<!--
/**
 *@author hhy 
 *@date 2023-2-9
  财务管理 对账管理 平台收支账单明细 
*/
-->
<template>
  <custom-list
    warm-tips=""
    table-title="平台收支账单明细"
    :show-header="true"
    :show-top-buttons="true"
    :search-data="searchData"
    :search-params="searchParams"
    :table-columns="tableColumns"
    :header-buttons="headerButtons"
    :page-top-buttons="pageTopButtons"
    :table-operate-buttons="tableOperateButtons"
    :request-method="reconciliationApi.queryPlatformBillDetailForPage"
    @clear-form="clearForm" />
</template>

<script>
  import { offlineExport, code } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { commission, reconciliationApi } from '@/service/apis';
  import CustomList from '@/components/biz/CustomList.vue';

  export default {
    name: 'PlatformReceiptsPaymentsStatementsDetail',
    components: { CustomList },
    data() {
      return {
        id: '',
        reconciliationApi,
        dictionary: {},
        polymerizationInfo: {},
        pageButtons: [
          {
            id: '2',
            text: '导出',
            type: 'plain',
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        tableData: [],

        tableColumns: [
          { label: '订单号', prop: 'tradeSeq', minWidth: '200px' },
          { label: '电站名称', prop: 'stationName', minWidth: '140px' },
          { label: '红包抵扣', prop: 'redPackDiscountAmount', minWidth: '100px' },
          { label: '商户名称', prop: 'operatorName', minWidth: '140px' },
          { label: '结算时间', prop: 'tradeTime', minWidth: '160px' },
          { label: '用户账号', prop: 'userAccount', minWidth: '140px' },
          { label: '归属客户', prop: 'orgName', minWidth: '160px' },
          { label: '平台电费活动抵扣', prop: 'activityChargedDiscountAmount', minWidth: '150px' },
          { label: '平台服务费活动抵扣', prop: 'activityServiceDiscountAmount', minWidth: '150px' },
          {
            label: '平台电费卡券抵扣',
            prop: 'creditCardChargedDiscountAmount',
            minWidth: '150px',
          },
          {
            label: '平台服务费卡券抵扣',
            prop: 'creditCardServiceDiscountAmount',
            minWidth: '150px',
          },
          {
            label: '会员电费优惠',
            prop: 'memberCreditChargedDiscountAmount',
            minWidth: '100px',
          },
          {
            label: '会员服务费优惠',
            prop: 'memberCreditServiceDiscountAmount',
            minWidth: '100px',
          },
          {
            label: '抽成费用',
            prop: 'totalCommissionAmt',
            minWidth: '100px',
          },
        ],
        tableOperateButtons: [
          // {
          //   id: '1',
          //   text: '编辑',
          //   handleClick: (btn, { row }) => {
          //     this.$router.push({
          //       path: '/financeManage/agentCommissionApplication/edit',
          //       query: { id: row.id },
          //     });
          //   },
          // },
          // {
          //   id: '2',
          //   text: '详情',
          //   handleClick: (btn, { row }) => {
          //     this.$router.push({
          //       path: '/financialManagement/subsidiaryAccount/subDriverAccountsDetail',
          //       query: { id: row.id },
          //     });
          //   },
          // },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          // userAccount: '',
          // userName: '',
          // phoneNumber: '',
          // userId: '',
          // belongTo: '',
        },
        showDrawer: false,
        isEdit: false,
        billTime: '',
        billDateType: '',
      };
    },
    created() {
      const { billTime, billDateType } = this.$route.query;
      this.searchParams.billTime = billTime;
      this.searchParams.dateType = billDateType;
      this.billTime = billTime;
      this.billDateType = billDateType;
    },
    computed: {
      headerButtons() {
        return [
          {
            label: '导出',
            enabled: () => code('finance:reconci:platform:export'),
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              offlineExport(
                {
                  downloadType: 'platform_order_bill_detail',
                  jsonNode: {
                    ...ctx.searchParams,
                  },
                },
                ctx.pageInfo.total
              );
            },
          },
        ];
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'tradeSeq',
            label: '充电单号',
            placeholder: '请输入充电单号',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'operatorName',
            label: '商户名称',
            placeholder: '请输入商户名称',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '用户账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcInput',
            key: 'orgName',
            label: '归属客户',
            placeholder: '请输入归属客户',
          },
        ];
      },
    },
    methods: {
      clearForm(data) {
        this.searchParams = {
          ...data,
          // operatorId: this.id,
          billTime: this.billTime,
          dateType: this.billDateType,
        };
      },
      handleChange(event, data) {
        console.log('event:', event);
        console.log('data:', data);
      },
      changeStateSearch() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          commission.list({
            ...this.pageInfo,
            body: this.searchParams,
          }),
          commission.polymerization(this.searchParams),
        ]).then(([res1, res2]) => {
          this.tableData = res1.body;
          this.pageInfo.total = res1.totalCount || 0;
          this.polymerizationInfo = res2.body;
        });
      },
      /** 导出文件 */
      doExportFile() {
        commission.export('代理人佣金列表', this.searchParams);
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        // if (form.createTime.length > 0) {
        //   [this.searchParams.createBeginTime, this.searchParams.createEndTime] = form.createTime;
        // }
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
    },
  };
</script>
<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
